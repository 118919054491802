import { call, put, takeLatest } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import {
  DELETE,
  FIND_USUARIOS_ATIVOS_REQUISICOES,
  GET_LER_ID_REQUISICAO_PAGTO_DIRETO_1_ACTION,
  GET_LER_ID_REQUISICAO_PAGTO_DIRETO_2_ACTION,
  GET_REQUISICAO_BY_ID,
  GET_REQUISICOES,
  SAVE,
} from './requisicao.constants';
import {
  deleteRequisicao,
  editRequisicao,
  getAllRequisicoes,
  getRequisicaoById,
  getUsuariosAtivosRequisicao,
  insertRequisicao,
  lerIdRequisicaoPagtoDireto1,
  lerIdRequisicaoPagtoDireto2,
} from '../../services/core/requisicoes/requisicoes.services';
import {
  clearEditorTextoAction,
  saveRequisicaoFailureAction,
  saveRequisicaoSuccessAction,
  setAlertDialogValuesAction,
  setAttributesAction,
} from './requisicao.store';
import { buildRequestFields, buildUsuariosAtivosRequisicoes } from '../../helpers/factory/requisicao.factory';
import {
  buildPayload,
  getExceptionMessage,
  isBadRequestException,
  isConflictException,
  isNotFoundException,
} from '../../helpers/utils/utils';
import { getExceptionHandler, isExceptionFromValidations } from '../../helpers/utils/exception.util';
import { buildEmptyEditorTexto, isErrorOnEditorTexto } from '../../components/UI/EditorTexto/editorTexto.utils';

export const findUsuariosAtivosRequisicoesAction = idRequisicao => ({
  type: FIND_USUARIOS_ATIVOS_REQUISICOES,
  idRequisicao,
});

export const getAllRequisicoesAction = () => ({
  type: GET_REQUISICOES,
});

export const getLerIdRequisicaoPagtoDireto1Action = () => ({
  type: GET_LER_ID_REQUISICAO_PAGTO_DIRETO_1_ACTION,
});

export const getLerIdRequisicaoPagtoDireto2Action = () => ({
  type: GET_LER_ID_REQUISICAO_PAGTO_DIRETO_2_ACTION,
});

export const getRequisicaoByIdAction = idRequisicao => ({
  type: GET_REQUISICAO_BY_ID,
  idRequisicao,
});

export const saveRequisicaoAction = (isEditMode, idRequisicao) => ({
  type: SAVE,
  isEditMode,
  idRequisicao,
});

export const deleteRequisicaoAction = idRequisicao => ({
  type: DELETE,
  idRequisicao,
});

function getPayload(requestFields, idRequisicao) {
  const descricaoAtividades = buildEmptyEditorTexto(requestFields.descricaoAtividades);
  return {
    ...buildPayload(requestFields),
    descricaoAtividades,
    idRequisicao,
  };
}

function* searchRequisicoes() {
  const { data } = yield call(getAllRequisicoes);
  yield put(setAttributesAction('content', data));
}

function* catchSubmitException(exception, requestFields, updateTable) {
  const entityWithError = isBadRequestException(exception) && isExceptionFromValidations(exception);
  const entityDontExists = isNotFoundException(exception) || isConflictException(exception);
  const isDeleteError = !requestFields && !updateTable;

  if (isDeleteError) {
    yield put(setAlertDialogValuesAction(true, getExceptionMessage(exception)));
    yield put(setAttributesAction('showCrudDialog', false));
  }

  if (entityWithError) {
    const [updatedFields] = yield getExceptionHandler(exception, requestFields);
    yield put(saveRequisicaoFailureAction(updatedFields));

    if (isErrorOnEditorTexto(exception.response.data.validations, 'descricaoAtividades')) {
      yield put(clearEditorTextoAction());
    }
  } else if (entityDontExists) {
    yield put(setAlertDialogValuesAction(true, getExceptionMessage(exception)));
    yield put(setAttributesAction('showCrudDialog', false));

    if (updateTable) {
      yield searchRequisicoes();
    }
  }
}

function* getSaveRequest(isEditMode, idRequisicao, payload) {
  return isEditMode ? yield editRequisicao(idRequisicao, payload) : yield insertRequisicao(payload);
}

function* getLerIdRequisicaoPagtoDireto1Handler() {
  yield put(setAttributesAction('loading', true));
  try {
    const idRequisicaoPagtoDireto = yield call(lerIdRequisicaoPagtoDireto1);
    yield put(setAttributesAction('idRequisicaoPagtoDireto1', idRequisicaoPagtoDireto));
  } catch (exception) {
    // Nenhum tratamento definido de quando não encontrar requisições.
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* getLerIdRequisicaoPagtoDireto2Handler() {
  yield put(setAttributesAction('loading', true));
  try {
    const idRequisicaoPagtoDireto = yield call(lerIdRequisicaoPagtoDireto2);
    yield put(setAttributesAction('idRequisicaoPagtoDireto2', idRequisicaoPagtoDireto));
  } catch (exception) {
    // Nenhum tratamento definido de quando não encontrar requisições.
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* getRequisicoesHandler() {
  yield put(setAttributesAction('loading', true));
  try {
    yield searchRequisicoes();
  } catch (exception) {
    // Nenhum tratamento definido de quando não encontrar requisições.
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* getRequisicaoByIdHandler(actions) {
  const { idRequisicao } = actions;
  yield put(setAttributesAction('loading', true));
  try {
    const { data } = yield call(getRequisicaoById, idRequisicao);
    const requestFields = yield buildRequestFields(data);
    yield put(setAttributesAction('requestFields', requestFields));
    yield put(setAttributesAction('showCrudDialog', true));
  } catch (exception) {
    yield catchSubmitException(exception, null, true);
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* saveRequisicaoHandler({ isEditMode, idRequisicao }) {
  const requestFields = yield select(state => state.requestFields);
  yield put(setAttributesAction('loading', true));
  try {
    const payload = yield call(getPayload, requestFields, idRequisicao);
    yield getSaveRequest(isEditMode, idRequisicao, payload);
    yield put(saveRequisicaoSuccessAction(isEditMode));
    yield searchRequisicoes();
  } catch (exception) {
    yield catchSubmitException(exception, requestFields, true);
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

function* removeRequisicaoHandler({ idRequisicao }) {
  yield put(setAttributesAction('loading', true));
  try {
    yield call(deleteRequisicao, idRequisicao);
  } catch (exception) {
    yield catchSubmitException(exception, null, false);
  } finally {
    yield searchRequisicoes();
    yield put(setAttributesAction('loading', false));
  }
}

function* findUsuariosAtivosRequisicoesHandler(actions) {
  yield put(setAttributesAction('loading', true));
  try {
    const { idRequisicao } = actions;
    const { data } = yield getUsuariosAtivosRequisicao(idRequisicao);
    const usuarios = yield buildUsuariosAtivosRequisicoes(data);
    yield put(setAttributesAction('usuariosAssociados', usuarios));
  } finally {
    yield put(setAttributesAction('loading', false));
  }
}

export default function* watchRequisicoes() {
  yield takeLatest(GET_LER_ID_REQUISICAO_PAGTO_DIRETO_1_ACTION, getLerIdRequisicaoPagtoDireto1Handler);
  yield takeLatest(GET_LER_ID_REQUISICAO_PAGTO_DIRETO_2_ACTION, getLerIdRequisicaoPagtoDireto2Handler);
  yield takeLatest(GET_REQUISICOES, getRequisicoesHandler);
  yield takeLatest(GET_REQUISICAO_BY_ID, getRequisicaoByIdHandler);
  yield takeLatest(SAVE, saveRequisicaoHandler);
  yield takeLatest(DELETE, removeRequisicaoHandler);
  yield takeLatest(FIND_USUARIOS_ATIVOS_REQUISICOES, findUsuariosAtivosRequisicoesHandler);
}
