import { IconButton, TableRow } from '@material-ui/core';
import React from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { TableCellCenter, TableCellRight } from '../../../../components/UI/Tabela/tableDefaultStyles';
import { getMoneyMask, toBrazilianDate } from '../../../../helpers';
import { blackTwo } from '../../../../helpers/constants/colors.constants';


function TableBodyAnaliticaComponent({ ficha }) {
  return (
    <TableRow>
      <TableCellCenter>
        {ficha.automatica === 'N'
        && (
          <IconButton
            size="small"
            disabled="true"
          >
            <AiFillCheckCircle
              color={blackTwo}
              size={16}
            />
          </IconButton>
        )
      }
      </TableCellCenter>
      <TableCellCenter>
        {toBrazilianDate(ficha.dataLancamento)}
      </TableCellCenter>
      <TableCellCenter>
        {ficha.acordo}
      </TableCellCenter>
      <TableCellCenter>
        {ficha.parcela}
      </TableCellCenter>
      <TableCellRight>
        {getMoneyMask(ficha.valorParcela)}
      </TableCellRight>
      <TableCellCenter>
        {ficha.titulo}
      </TableCellCenter>
      <TableCellRight>
        {getMoneyMask(ficha.valorPrincipalCliente)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorMulta)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorProtesto)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorJurosCliente)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorComissaoPagaCliente)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorHonorarios + ficha.valorJurosABE)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorComercial)}
      </TableCellRight>
      <TableCellRight>
        {getMoneyMask(ficha.valorNegociador)}
      </TableCellRight>
    </TableRow>
  );
}

export const TableBodyAnalitica = TableBodyAnaliticaComponent;
