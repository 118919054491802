import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableHeader from '../../../models/TableHeader';
import { useRequisicaoDispatch, useRequisicaoSelector } from '../../../store/Requisicao/RequisicaoProvider';
import BasicActionsTable from '../../UI/Tabela/PaginadaSimples/BasicActons/BasicActions';
import SnackbarUI from '../../UI/Snackbar/Snackbar';
import { ButtonNewRequisicao, Container } from './pesquisaRequisicao.styles';
import TableUI from '../../UI/Tabela/PaginadaSimples/Table';
import { resetRequestFieldsAction, setAttributesAction } from '../../../store/Requisicao/requisicao.store';
import {
  BUTTON_NEW,
  EDITAR_CADASTRO,
  NOVO_CADASTRO,
  removalDialogMessage,
  REQUISICOES,
} from './pesquisaRequisicao.constants';
import WrapperContent from '../../UI/WrapperContent/WrapperContent';
import LoadingUI from '../../UI/Loading/LoadingUI';
import {
  deleteRequisicaoAction,
  findUsuariosAtivosRequisicoesAction,
  getAllRequisicoesAction,
  getLerIdRequisicaoPagtoDireto1Action,
  getLerIdRequisicaoPagtoDireto2Action,
  getRequisicaoByIdAction,
} from '../../../store/Requisicao/requisicao.saga';
import CrudRequisicoes from '../Crud/CrudRequisicoes';
import { BoldContentDialog } from '../../UI/Dialogs/Content/Bold/BoldContentDialog';
import AlertDialog from '../../UI/Dialogs/AlertDialog/AlertDialog';
import OptionDialogNew from '../../UI/Dialogs/OptionDialog/OptionDialogNew';


const columns = [
  new TableHeader({
    id: 'enviarNotificacao', label: 'Notificação', alignCell: 'center', width: 1,
  }),
  new TableHeader({
    id: 'nome', label: 'Nome da Requisição', alignCell: 'left', width: 480,
  }),
  new TableHeader({
    id: 'acoes', label: 'Ações', alignCell: 'center', width: 50,
  }),
];

function PesquisaRequisicao() {
  const [rows, setRows] = useState([]);
  const dispatch = useRequisicaoDispatch();

  const requisicoes = useRequisicaoSelector(states => states.content);
  const snackbar = useRequisicaoSelector(states => states.snackbar);
  const alert = useRequisicaoSelector(states => states.alert);

  const loading = useRequisicaoSelector(states => states.loading);
  const showCrudDialog = useRequisicaoSelector(states => states.showCrudDialog);

  const idRequisicaoPagtoDireto1 = useRequisicaoSelector(states => states.idRequisicaoPagtoDireto1);
  const idRequisicaoPagtoDireto2 = useRequisicaoSelector(states => states.idRequisicaoPagtoDireto2);

  const permissoesUsuario = useSelector(states => states.authentication.userInformation);
  const hasCreatePermission = (`[${permissoesUsuario.permissoes}]`).includes(1101);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [idRequisicao, setIdRequisicao] = useState();
  const [routeNames, setRouteNames] = useState([REQUISICOES]);
  const [optinalDialogIngo, setOptionalDialogInfo] = useState({ folderName: '', show: false });

  useEffect(() => {
    dispatch(getLerIdRequisicaoPagtoDireto1Action());
    dispatch(getLerIdRequisicaoPagtoDireto2Action());
    dispatch(getAllRequisicoesAction());
  }, [dispatch]);


  const setCreateEditMode = useCallback((isCreate, isEdit) => {
    setIsEditMode(isEdit);
    setIsCreateMode(isCreate);
  }, [setIsEditMode]);

  const openNewRequisicaoButtonHandler = useCallback(() => {
    dispatch(resetRequestFieldsAction());
    setRouteNames([NOVO_CADASTRO]);
    setCreateEditMode(true, false);
    dispatch(setAttributesAction('showCrudDialog', true));
  }, [dispatch, setCreateEditMode]);

  const onDetailsHandler = useCallback((idRequisicaoParam) => {
    dispatch(getRequisicaoByIdAction(idRequisicaoParam));
    dispatch(findUsuariosAtivosRequisicoesAction(idRequisicaoParam));
    setRouteNames([REQUISICOES]);
    setCreateEditMode(false, false);
  }, [setCreateEditMode, dispatch]);

  const onEditHandler = useCallback((idRequisicaoParam) => {
    dispatch(getRequisicaoByIdAction(idRequisicaoParam));
    setCreateEditMode(false, true);
    setRouteNames([EDITAR_CADASTRO]);
  }, [setCreateEditMode, dispatch]);

  const openRemoverDialogHandler = useCallback((idRequisicaoParam, name) => {
    setOptionalDialogInfo({ folderName: name, show: true });
    setIdRequisicao(idRequisicaoParam);
  }, [setIdRequisicao, setOptionalDialogInfo]);

  const closeRemoverDialogHandler = () => {
    setIdRequisicao(null);
    setOptionalDialogInfo(state => ({ ...state, show: false }));
  };

  const confirmRemoverDialogHandler = () => {
    dispatch(deleteRequisicaoAction(idRequisicao));
    setIdRequisicao(null);
    setOptionalDialogInfo(state => ({ ...state, show: false }));
  };

  const buildTableRow = useCallback(row => ({
    id: row.idRequisicao,
    values: {
      enviarNotificacao: row.enviarNotificacao ? 'Sim' : 'Não',
      nome: row.nome,
      acoes: (
        <BasicActionsTable
          id={row.idRequisicao}
          idRequisicaoPagtoDireto1={idRequisicaoPagtoDireto1?.data}
          idRequisicaoPagtoDireto2={idRequisicaoPagtoDireto2?.data}
          isRequisicao
          onDetails={() => onDetailsHandler(row.idRequisicao)}
          onEdit={() => onEditHandler(row.idRequisicao)}
          onRemove={() => openRemoverDialogHandler(row.idRequisicao, row.nome)}
          allows={{
            editar: 1101,
            excluir: 1101,
          }}
          userAllow="U"
        />
      ),
    },
  }), [onDetailsHandler, onEditHandler, openRemoverDialogHandler, idRequisicaoPagtoDireto1, idRequisicaoPagtoDireto2]);

  useEffect(() => {
    setRows(requisicoes.map(buildTableRow));
  }, [setRows, requisicoes, buildTableRow]);

  return (
    <WrapperContent>
      <LoadingUI show={loading} />
      <SnackbarUI
        open={snackbar.show}
        variant="success"
        message={snackbar.message}
        onClose={() => dispatch(setAttributesAction('snackbar', { ...snackbar, show: false }))}
      />
      <OptionDialogNew
        open={optinalDialogIngo.show}
        onClickCancel={closeRemoverDialogHandler}
        onClickConfirm={confirmRemoverDialogHandler}
        confirmLabel="Confirmar"
        cancelLabel="Cancelar"
      >
        <div style={{ fontSize: '16px', display: 'flex', textAlign: 'justify' }}>
          <p>
            {removalDialogMessage(optinalDialogIngo.folderName)}
          </p>
        </div>
      </OptionDialogNew>
      <AlertDialog
        open={alert.show}
        variant="error"
        onClickConfirm={() => dispatch(setAttributesAction('alert', { ...alert, show: false }))}
      >
        <BoldContentDialog pontoFinalNaUltimaMsg fullyMessage={alert.message} />
      </AlertDialog>
      <Container>
        <ButtonNewRequisicao
          type="button"
          disabled={!hasCreatePermission}
          onClick={openNewRequisicaoButtonHandler}
        >
          {BUTTON_NEW}
        </ButtonNewRequisicao>

        <CrudRequisicoes
          open={showCrudDialog}
          onClose={() => dispatch(setAttributesAction('showCrudDialog', false))}
          routesName={routeNames}
          isCreateMode={isCreateMode}
          isEditMode={isEditMode}
        />

        <TableUI
          maxTableHeigth="500px"
          isScrollable
          columns={columns}
          rows={rows}
          rowsPerPage={13}
        />
      </Container>
    </WrapperContent>
  );
}

export default withRouter(PesquisaRequisicao);
