import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import DeleteForever from '@material-ui/icons/DeleteForever';
import PermissionIconButton from '../../../Button/Permission/IconButton/PermissionIconButton';
import useStyles from './BasicActions.styles';
import { checkPermission } from '../../../../../helpers/utils/utils';
import MigrarIcon from '../../../../../assets/Icons/MigrarIcon';


function BasicActionsTable({
  id,
  nome,
  onEdit,
  onMigrarParaCliente,
  onDetails,
  onRemove,
  allows,
  userAllow,
  isCliente,
  isRequisicao = false,
  idRequisicaoPagtoDireto1 = 0,
  idRequisicaoPagtoDireto2 = 0,
  userPermissions,
}) {
  const styles = useStyles();
  const cantMigrar = checkPermission(userPermissions, allows.migrar, 'U', userAllow);

  return (
    <div className={styles.wrapper}>
      <PermissionIconButton
        idAllow={allows.consultar}
        userAllow={userAllow}
        title="Consultar"
        onClick={() => onDetails(id)}
      >
        <ZoomInIcon />
      </PermissionIconButton>
      <PermissionIconButton
        idAllow={allows.editar}
        userAllow={userAllow}
        disabled={isRequisicao && (id === idRequisicaoPagtoDireto1 || id === idRequisicaoPagtoDireto2)}
        title="Editar"
        onClick={() => onEdit(id)}
      >
        <EditIcon />
      </PermissionIconButton>
      <PermissionIconButton
        title="Excluir"
        idAllow={allows.excluir}
        userAllow={userAllow}
        disabled={isRequisicao && (id === idRequisicaoPagtoDireto1 || id === idRequisicaoPagtoDireto2)}
        onClick={() => onRemove(id, nome)}
      >
        <DeleteForever />
      </PermissionIconButton>
      {isCliente !== undefined && (
        <PermissionIconButton
          disabled={isCliente || cantMigrar}
          title="Migrar"
          idAllow={allows.migrar}
          userAllow={userAllow}
          onClick={() => onMigrarParaCliente(id)}
        >
          <MigrarIcon />
        </PermissionIconButton>
      )}
    </div>
  );
}

export default BasicActionsTable;
