export const GET_REQUISICOES = '@requisicao/GET_ALL';
export const GET_LER_ID_REQUISICAO_PAGTO_DIRETO_1_ACTION = '@requisicao/GET_LER_ID_REQUISICAO_PAGTO_DIRETO_1_ACTION';
export const GET_LER_ID_REQUISICAO_PAGTO_DIRETO_2_ACTION = '@requisicao/GET_LER_ID_REQUISICAO_PAGTO_DIRETO_2_ACTION';
export const GET_REQUISICAO_BY_ID = '@requisicao/GET_BY_ID';
export const SAVE = '@requisicao/SAVE';
export const SAVE_SUCCESSFUL = '@requisicao/SAVE_SUCCESSFUL';
export const SAVE_FAILURE = '@requisicao/SAVE_FAILURE';
export const SET_ATTRIBUTES = '@requisicao/SET_ATTRIBUTES';
export const SET_REQUEST_FIELD_VALUE = '@requisicao/SET_REQUEST_FIELD_VALUE';
export const SET_REQUEST_FIELD_FOCUS = '@requisicao/SET_REQUEST_FIELD_FOCUS';
export const RESET_REQUEST_FIELDS = '@requisicao/RESET_REQUEST_FIELDS';
export const SET_ALERT_DIALOG = '@requisicao/SET_ALERT_DIALOG';
export const DELETE = '@requisicao/DELETE';
export const CLEAR_EDITOR_TEXTO = '@requisicao/CLEAR_EDITOR_TEXTO';
export const FIND_USUARIOS_ATIVOS_REQUISICOES = 'FIND_USUARIOS_ATIVOS_REQUISICOES';
