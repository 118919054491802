import FieldModel from '../../models/FieldModel';
import {
  SET_LISTA_PROCESSOS_ACTION,
  SET_NUM_PROCESSO_ACTION,
  SET_PASSO_UM_CONTROLS_ACTION,
  SET_PASSO_UM_DATA_ACTION, SET_SIMPLE_CONTROLS_STEP_STATE_ACTION, SET_SIMPLE_PAGAMENTO_DIRETO_STATE_ACTION,
} from './PagamentoDireto.constants';
import { DateUtils, getMoneyMask } from '../../helpers';
import SelectorModel from '../../models/SelectorModel';

// states
function buildPassoUmData(data) {
  return {
    nomeCliente: data?.nomeCliente || '',
    codigoCliente: data?.codigoCliente || '',
    idProcesso: data?.idProcesso || '',
    codigoDevedor: data?.codigoDevedor || '',
    nomeDevedor: data?.nomeDevedor || '',
    codigoNoCliente: data?.codigoNoCliente || '',
    valorOriginalProcesso: data?.valorOriginalProcesso ? getMoneyMask(data.valorOriginalProcesso) : '',
    valorProcesso: data?.valorProcesso ? getMoneyMask(data.valorProcesso) : '',
    dataExpiracao: data?.dataExpiracao ? DateUtils.formatTextDate(data.dataExpiracao) : '',
    nomeUsuarioNegociador: data?.nomeUsuarioNegociador || '',
    idFase: data?.idFase || '',
    nomeFase: data?.nomeFase || '',
    identificacaoInterna: data?.identificacaoInterna || '',
    textoAcompanhamento: data?.textoAcompanhamento || '',
  };
}

function buildPassoUmControls(data) {
  return {
    numProcesso: new FieldModel({
      name: 'numProcesso',
      value: data?.numProcesso || '',
    }),
  };
}


function buildInitialStates() {
  return {
    activeStep: 0,
    loadingPage: false,
    openDialogErroPassoUm: false,
    messageDialogErroPassoUm: '',
    disabledProximoPassoUm: true,
    listaProcessosrequisicaoPagtoDireto: [],
    controls: {
      passoUm: buildPassoUmControls(),
    },
    data: {
      passoUm: buildPassoUmData(),
    },
  };
}

// actions
export const setSimplePagamentoDiretoStateAction = (name, value) => ({
  type: SET_SIMPLE_PAGAMENTO_DIRETO_STATE_ACTION,
  name,
  value,
});

export const setNumProcessoAction = value => ({
  type: SET_NUM_PROCESSO_ACTION,
  value,
});

export const setSimpleControlsStepStateAction = (stepName, propertyName, value) => ({
  type: SET_SIMPLE_CONTROLS_STEP_STATE_ACTION,
  stepName,
  propertyName,
  value,
});

export const setPassoUmDataAction = data => ({
  type: SET_PASSO_UM_DATA_ACTION,
  data,
});

export const setListaProcessosAction = data => ({
  type: SET_LISTA_PROCESSOS_ACTION,
  data,
});

export const setPassoUmControlsAction = data => ({
  type: SET_PASSO_UM_CONTROLS_ACTION,
  data,
});

// handlers
function setSimplePagamentoDiretoStateHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    [name]: value,
  };
}

function setSimpleControlsStepStateHandler(states, actions) {
  const { stepName, propertyName, value } = actions;

  const field = states.controls[stepName][propertyName];

  return {
    ...states,
    controls: {
      [stepName]: {
        ...states.controls[stepName],
        [propertyName]: field.onChange(value),
      },
    },
  };
}

function setPassoUmDataActionHandler(states, actions) {
  const { data } = actions;
  return {
    ...states,
    data: {
      passoUm: buildPassoUmData(data),
    },
  };
}

function setNumProcessoHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    controls: {
      passoUm: {
        numProcesso: new FieldModel({
          name: 'numProcesso',
          value: value.toString(),
        }),
      },
    },
  };
}
export function buildSelectorProcessos(listaProcessosrequisicaoPagtoDireto) {
  return listaProcessosrequisicaoPagtoDireto.map(data => new SelectorModel(
    data, data,
  ));
}
function setListaProcessosHandler(states, actions) {
  const { data } = actions;
  return {
    ...states,
    listaProcessosrequisicaoPagtoDireto: buildSelectorProcessos(data),
  };
}

function setPassoUmControlsHandler(states, actions) {
  const { data } = actions;
  return {
    ...states,
    controls: {
      passoUm: buildPassoUmControls(data),
    },
  };
}

// reducer
export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_SIMPLE_PAGAMENTO_DIRETO_STATE_ACTION:
      return setSimplePagamentoDiretoStateHandler(states, actions);
    case SET_SIMPLE_CONTROLS_STEP_STATE_ACTION:
      return setSimpleControlsStepStateHandler(states, actions);
    case SET_PASSO_UM_DATA_ACTION:
      return setPassoUmDataActionHandler(states, actions);
    case SET_PASSO_UM_CONTROLS_ACTION:
      return setPassoUmControlsHandler(states, actions);
    case SET_NUM_PROCESSO_ACTION:
      return setNumProcessoHandler(states, actions);
    case SET_LISTA_PROCESSOS_ACTION:
      return setListaProcessosHandler(states, actions);


    default:
      return states;
  }
};
