import {
  Box, Typography,
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Buttons, StepSubLabel } from '../../../pages/InspecionarProcesso/Acordos/AcordosStyle';
import useStylesPassoUm, { ButtonErro, DialogNewErro } from './PassoUm.style';
import { InputField, SelectorField } from '../..';
import SearchButton from '../../UI/Button/Search/SearchButton';
import { usePagamentoDiretoSelector, usePagamentoDiretoDispatch } from '../../../store/PagamentoDireto/PagamentoDiretoProvider';
import { setNumProcessoAction, setSimpleControlsStepStateAction, setSimplePagamentoDiretoStateAction } from '../../../store/PagamentoDireto/PagamentoDireto.store';
import { } from '../../../pages/AcordoSeguro/Inspecionar/InspecionarASPage.styles';
import { PesquisarInfoProcessoAction } from '../../../store/PagamentoDireto/PagamentoDireto.saga';


export default function PassoUm({
  handleNextStep, passoUmControls, setSelectedIds,
}) {
  const dispatch = usePagamentoDiretoDispatch();

  const stylePassoUm = useStylesPassoUm();
  const passoUmData = usePagamentoDiretoSelector(state => state.data.passoUm);
  const openDialogErroPassoUm = usePagamentoDiretoSelector(state => state.openDialogErroPassoUm);
  const messageDialogErroPassoUm = usePagamentoDiretoSelector(state => state.messageDialogErroPassoUm);
  const disabledProximoPassoUm = usePagamentoDiretoSelector(state => state.disabledProximoPassoUm);
  const listaProcessosrequisicaoPagtoDireto = usePagamentoDiretoSelector(state => state.listaProcessosrequisicaoPagtoDireto);

  const [idProcessoRequisicao] = useState(null);

  const handleSubmitSearchForm = useCallback((e) => {
    e.preventDefault();
    dispatch(PesquisarInfoProcessoAction());
    setSelectedIds({});
  }, [dispatch, setSelectedIds]);

  const handleChangeControlPassoUm = useCallback((e) => {
    const { name, value } = e.target;
    dispatch(setSimpleControlsStepStateAction('passoUm', name, value));
  }, [dispatch]);

  const onChangeProcessoSelectorFields = useCallback((event) => {
    const { value } = event.target;
    dispatch(setNumProcessoAction(value));
  }, [dispatch]);

  const applyStylePesquisaField = (field = '', label) => {
    let finalField = field;
    if (typeof field === 'string') {
      finalField = field.trim();
    }
    if (finalField !== '' && finalField !== null) {
      return (
        <span>
          <b>
            {' '}
            -
            {' '}
            {label}
            :
            {' '}
          </b>
          { field }
        </span>
      );
    }
    return (
      <span>
        <b>
          {' '}
          -
          {' '}
          {label}
          :
          {' '}
        </b>
      </span>
    );
  };


  return (
    <>
      <DialogNewErro
        open={openDialogErroPassoUm}
      >
        <Typography style={{ textAlign: 'left' }}>
          {messageDialogErroPassoUm}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'end', marginTop: '22px' }}>
          <ButtonErro
            type="submit"
            onClick={() => dispatch(setSimplePagamentoDiretoStateAction('openDialogErroPassoUm', false))}
          >
            OK, ENTENDI
          </ButtonErro>
        </div>
      </DialogNewErro>
      <StepSubLabel>
        <Typography>Informe o Processo que contém os Títulos que deseja quitar total ou parcialmente:</Typography>
      </StepSubLabel>
      <Box>
        <Box className={stylePassoUm.boxPesquisa}>
          <form onSubmit={handleSubmitSearchForm} className={stylePassoUm.formPesquisa}>
            <Typography className={stylePassoUm.numeroProcessoStyle}>
              Número do Processo:
            </Typography>
            <InputField
              defaultValue=""
              name="numProcesso"
              value={passoUmControls?.numProcesso.value}
              onChange={handleChangeControlPassoUm}
              maxLength={9}
            />
            <SearchButton
              style={{
                marginLeft: '16px',
              }}
              type="submit"
              disabled={passoUmControls.numProcesso.value.trim() === ''}
            >
              <SearchIcon />
            </SearchButton>
            {listaProcessosrequisicaoPagtoDireto.length > 0 && (
              <div className={stylePassoUm.div__fieldProcessoRequisicao}>
                <SelectorField
                  hasEmptyLabel
                  isForm
                  label="Processos Com Requisição"
                  name="idProcessoRequisicao"
                  value={idProcessoRequisicao}
                  items={listaProcessosrequisicaoPagtoDireto}
                  onChange={onChangeProcessoSelectorFields}
                />
              </div>
            )}
          </form>
        </Box>
        <Box className={stylePassoUm.boxDadosPesquisa}>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(`${passoUmData.codigoCliente} ${passoUmData.nomeCliente}`, 'Cliente')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.identificacaoInterna, 'Identificação interna')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(`${passoUmData.codigoDevedor} ${passoUmData.nomeDevedor}`, 'Devedor')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.codigoNoCliente, 'Código no Cliente')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.valorProcesso, 'Valor do Processo')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.valorOriginalProcesso, 'Valor Original do Processo')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.dataExpiracao, 'Data da Expiração')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.nomeUsuarioNegociador, 'Negociador')}</Typography>
          <Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.nomeFase, 'Fase')}</Typography>
          {passoUmData?.textoAcompanhamento !== '' && (<Typography className={stylePassoUm.textoDadoPesquisa}>{applyStylePesquisaField(passoUmData.textoAcompanhamento, 'Requisição')}</Typography>)}
        </Box>
        <Buttons
          disabled={disabledProximoPassoUm}
          style={{ marginTop: '40px' }}
          onClick={handleNextStep}
        >
          Próximo
        </Buttons>
      </Box>
    </>
  );
}
