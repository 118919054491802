import { TableRow } from '@material-ui/core';
import React from 'react';
import OrdenacaoColuna from '../../../../components/UI/Tabela/Ordenacao/OrdenacaoColuna';
import { TableCellCenter, TableCellRight } from '../../../../components/UI/Tabela/tableDefaultStyles';

function TableHeaderSinteticaComponent({
  setArrowOrdenacao,
  arrowOrdenacao,
  columnOrdenacao,
  setColumnOrdenacao,
  onClickOrdernacaoColumn,
}) {
  return (
    <TableRow>
      <TableCellCenter height={36} width={51}>
        Manual
      </TableCellCenter>
      <TableCellCenter height={36} width={103}>
        <OrdenacaoColuna
          columnLabel="Data"
          idColumn="dataLancamento"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => { onClickOrdernacaoColumn('dataLancamento'); }}
        />
      </TableCellCenter>
      <TableCellCenter height={36} width={74}>
        <OrdenacaoColuna
          columnLabel="Acordo"
          idColumn="acordo"
          arrowOrdenacao={arrowOrdenacao}
          columnOrdenacao={columnOrdenacao}
          setArrowOrdenacaoFunc={setArrowOrdenacao}
          setColumnOrdenacao={setColumnOrdenacao}
          onClickColumn={() => { onClickOrdernacaoColumn('acordo'); }}
        />
      </TableCellCenter>
      <TableCellCenter height={36} width={83}>
        Parcela
      </TableCellCenter>
      <TableCellRight height={36} width={127}>
        Valor da Parcela
      </TableCellRight>
      <TableCellRight height={36} width={131}>
        Principal Cliente
      </TableCellRight>
      <TableCellRight height={36} width={103}>
        Multa
      </TableCellRight>
      <TableCellRight height={36} width={111}>
        Protesto
      </TableCellRight>
      <TableCellRight height={36} width={109}>
        Juros Cliente
      </TableCellRight>
      <TableCellRight height={36} width={122}>
        Comissão paga pelo Cliente
      </TableCellRight>
      <TableCellRight height={36} width={109}>
        HB
      </TableCellRight>
      <TableCellRight height={36} width={109}>
        Comercial
      </TableCellRight>
      <TableCellRight height={36} width={109}>
        Negociador
      </TableCellRight>
    </TableRow>
  );
}

export const TableHeaderSintetica = TableHeaderSinteticaComponent;
