import { CORE, gateway, gatewayShortJson } from '../../api';

export function getAllRequisicoes() {
  return gateway.get(`${CORE}/requisicoes`);
}

export function lerIdRequisicaoPagtoDireto1() {
  return gateway.get(`${CORE}/requisicoes/ler_id_requisicao_pagto_direto1`);
}

export function lerIdRequisicaoPagtoDireto2() {
  return gateway.get(`${CORE}/requisicoes/ler_id_requisicao_pagto_direto2`);
}

export function getRequisicaoById(idRequisicao) {
  return gateway.get(`${CORE}/requisicoes/${idRequisicao}`);
}

export function insertRequisicao(payload) {
  return gateway.post(`${CORE}/requisicoes`, payload);
}

export function editRequisicao(idRequisicao, payload) {
  return gateway.put(`${CORE}/requisicoes/${idRequisicao}`, payload);
}

export function deleteRequisicao(idRequisicao) {
  return gateway.delete(`${CORE}/requisicoes/${idRequisicao}`);
}

export function getAllRequisicoesSimplificadas() {
  return gatewayShortJson.get(`${CORE}/requisicoes`);
}

export function getUsuariosAtivosRequisicao(idRequisicao) {
  return gateway.get(`${CORE}/requisicoes/${idRequisicao}/usuarios`);
}
