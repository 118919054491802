import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { BUSCAR_LISTA_PROCESSOS_REQUISICAO_PAGTO_DIRETO, PESQUISAR_INFO_PROCESSO } from './PagamentoDireto.constants';
import { setListaProcessosAction, setPassoUmDataAction, setSimplePagamentoDiretoStateAction } from './PagamentoDireto.store';
import { buscarListaProcessosRequisicaoPagtoDireto, buscarProcessoPassoUmPagamentoDireto } from '../../services/core/pagamentoDireto/pagamentoDireto.service';

export const PesquisarInfoProcessoAction = () => ({
  type: PESQUISAR_INFO_PROCESSO,
});

export const buscarListaProcessosRequisicaoPagtoDiretoAction = () => ({
  type: BUSCAR_LISTA_PROCESSOS_REQUISICAO_PAGTO_DIRETO,
});

function* PesquisarInfoProcessoHandler() {
  yield put(setSimplePagamentoDiretoStateAction('loadingPage', true));
  const { numProcesso } = yield select(state => state.controls.passoUm);
  try {
    const response = yield call(buscarProcessoPassoUmPagamentoDireto, numProcesso.value);
    yield put(setPassoUmDataAction(response.data));
    yield put(setSimplePagamentoDiretoStateAction('disabledProximoPassoUm', false));
  } catch (e) {
    yield put(setSimplePagamentoDiretoStateAction('openDialogErroPassoUm', true));
    yield put(setSimplePagamentoDiretoStateAction('messageDialogErroPassoUm', e.response.data.message));
    yield put(setSimplePagamentoDiretoStateAction('disabledProximoPassoUm', true));
  } finally {
    yield put(setSimplePagamentoDiretoStateAction('loadingPage', false));
  }
}

function* buscarListaProcessosRequisicaoPagtoDiretoHandler() {
  yield put(setSimplePagamentoDiretoStateAction('loadingPage', true));
  try {
    const response = yield call(buscarListaProcessosRequisicaoPagtoDireto);
    yield put(setListaProcessosAction(response.data));
  } catch (e) {
    yield put(setSimplePagamentoDiretoStateAction('openDialogErroPassoUm', true));
    yield put(setSimplePagamentoDiretoStateAction('messageDialogErroPassoUm', e.response.data.message));
    yield put(setSimplePagamentoDiretoStateAction('disabledProximoPassoUm', true));
  } finally {
    yield put(setSimplePagamentoDiretoStateAction('loadingPage', false));
  }
}


export default function* watchPagamentoDireto() {
  yield takeLatest(PESQUISAR_INFO_PROCESSO, PesquisarInfoProcessoHandler);
  yield takeLatest(BUSCAR_LISTA_PROCESSOS_REQUISICAO_PAGTO_DIRETO, buscarListaProcessosRequisicaoPagtoDiretoHandler);
}
